<template>
  <div style="margin-top: 40px;padding-bottom: 30px">
    <el-button-group>
      <el-button round="true" @click="studentShow" :class="{a: isShow,b: !isShow}">学生版</el-button>
      <el-button round="true" @click="teacherShow" :class="{a: !isShow,b: isShow}">教师版</el-button>
    </el-button-group>
    <!--    <el-button type="text" class="index_section_title"  :class="{a: !isShow,b: isShow}" >学生版</el-button>-->
    <!--    <el-button type="text" class="index_section_title" :class="{a: isShow,b: !isShow}" >教师版</el-button>-->
    <div class="page" name="first" v-show="studentPrise">
      <div class="big-title">全站试题实时更新 | 历年真题VIP免费作答</div>
      <div style="color: red;text-align: center">
        学生版-历年真题、模拟题、练习题 每日不定时更新 欢迎投稿 投稿送会员
      </div>
      <!--      <div style="color: red;text-align: center">-->
      <!--        提醒:价格会跟随试题数量的不断增加而增涨，建议尽早升级会员！（题库每日不定时更新）-->
      <!--      </div>-->
      <div
          style="display: flex;flex-wrap: nowrap;flex-direction: row;justify-content: space-between;margin-top: 50px;">
        <selfVipCard :isRecommend="'selfVipCard'" :vipType="typeInfo1"></selfVipCard>
        <selfVipCard :isRecommend="'selfVipCard'" :vipType="typeInfo2"></selfVipCard>
        <selfVipCard :isRecommend="'selfVipCard recommend'" :vipType="typeInfo3"></selfVipCard>
        <selfVipCard :isRecommend="'selfVipCard'" :vipType="typeInfo4"></selfVipCard>
      </div>
      <div>
        <p class="title">一套属于我们自己的在线考试辅导平台</p>
        <div style="display: flex;flex-wrap: wrap;justify-content: space-between;margin-top: 20px;">
          <div class="use-easy-one">
            <p style="font-size:24px;font-weight:900;text-align:center">历年真题</p>
            <p style="margin-top:10px;font-size:18px;text-align:center">
              NOIP、蓝桥杯、等级考试等历年试卷真题，打造VIP专属题库</p>
          </div>
          <div class="use-easy-one">
            <p style="font-size:24px;font-weight:900;text-align:center">模拟测试</p>
            <p style="margin-top:10px;font-size:18px;text-align:center">
              覆盖分类包含Scratch、C++、Python等多个领域，更贴近实际学习所需</p>
          </div>

          <div class="use-easy-one">
            <p style="font-size:24px;font-weight:900;text-align:center">不止提升</p>
            <p style="margin-top:10px;font-size:18px;text-align:center">
              累计合作百位专业教师，严控试题普及、考点、难易、严谨</p>
          </div>

          <div class="use-easy-one">
            <p style="font-size:24px;font-weight:900;text-align:center">引领行业</p>
            <p style="margin-top:10px;font-size:18px;text-align:center">
              甄选成套试题，历年真题、模拟试题、专属练习，知识绝不错过</p>
          </div>
        </div>
      </div>
      <br>
      <br>
      <br>
      <div style="text-align: left;font-size: 16px;margin-top: 5px">
        <div style="text-align: left;font-size: 16px;margin-top: 20px;font-weight:900;" class="el-icon-document"> 会员开通说明</div>
        <div style="text-align: left;font-size: 16px;margin-top: 20px"> 一旦成为本站的会员，即可获得相应级别会员权限，全站全科目题库皆可查看答案！</div>

        <div style="text-align: left;font-size: 16px;margin-top: 20px"> 1、提供高品质会员题库资源，免费更新题库。</div>

        <div style="text-align: left;font-size: 16px;margin-top: 20px"> 2、会员属于赞助全凭自愿，无任何强逼行为，仅仅是您对于本站的支持（相当于赞助本站的服务器与维护费用），用于提高访问体验！</div>

        <div style="text-align: left;font-size: 16px;margin-top: 20px" > 3、本站作为考级竞赛题目的信息收集整理，可以联系客服获取1对1试卷讲解（另外收费）。</div>

        <div style="text-align: left;font-size: 16px;margin-top: 20px" > 4、本产品为虚拟产品，付款后不予退款；最终解释权归本站所有。</div>
      </div>
      <a class="join-vip">加入VIP，在线做练习</a>
      <!--
          <form name="punchout_form" method="post"
            action="https://openapi.alipay.com/gateway.do?charset=utf-8&method=alipay.trade.page.pay&sign=gI1bBGYzJKPT7UR7GdmGnXFVq8AwH3Ey80H7ldwfKJLya7cPXCjjESTX8hDnnkYAW2LgbYbZYChAb%2Fgmw1nKvhOJ5x6LHgK8pdSWPNuEjCMzzoRorn9Vw3LuXKSCVzUWea4OzLl6x1q8Cv4nG2wGS%2Fmp7ZJIZkrKzOrxqPGHkSO16GYbhGiPnMCTUPnRgQDgvHYDLcOPq6%2BegLAl55ezoIxJEmKbiLxMnIj5377v2BL4EcwOqlgKI%2BNtAV%2BAupfcVxMrJ3AWTM2XmmiER0CfFq1%2Fe4Eu%2BseyXTownmm2U9rfY5IGiJJdyA3b6wxScVMiWt3OgtV3FW%2FO94Yeotv0cQ%3D%3D&return_url=http%3A%2F%2F180.174.255.110%3A8585%2Falipay.trade.page.pay-JAVA-UTF-8%2Freturn_url.jsp&notify_url=http%3A%2F%2Fwww.wancode.net%3A8585%2Fwancode%2Fback%2Falipay%2Fcallback&version=1.0&app_id=2021001146647173&sign_type=RSA2&timestamp=2021-04-11+17%3A58%3A50&alipay_sdk=alipay-sdk-java-dynamicVersionNo&format=json">
            <input type="hidden" name="biz_content"
              value="{&quot;out_trade_no&quot;:&quot;WANCODE_20210411175850&quot;,&quot;total_amount&quot;:&quot;199.00&quot;,&quot;subject&quot;:&quot;开通会员&quot;,&quot;body&quot;:&quot;&quot;,&quot;timeout_express&quot;:&quot;360m&quot;,&quot;passback_params&quot;:&quot;&quot;,&quot;product_code&quot;:&quot;FAST_INSTANT_TRADE_PAY&quot;}">
            <input type="submit" value="立即支付" style="display:none">
          </form> -->
      <!-- selfVIP -->

    </div>
    <div class="page" name="second" v-show="teacherPrise">
      <div class="big-title">学练考评一站式在线考试系统 | 让考试更简单</div>
      <div style="color: red;text-align: center">
        教师版-专注青少年编程在线考试、培训、刷题、模拟、练习，稳定高效，操作更简单   《体验账号：teacher &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;密码：teacher》
      </div>

      <div
          style="display: flex;flex-wrap: nowrap;flex-direction: row;justify-content: space-between;margin-top: 50px;">
        <selfTeacherVipCard :isRecommend="'selfVipCard'" :vipType="typeInfo1"></selfTeacherVipCard>
        <selfTeacherVipCard :isRecommend="'selfVipCard'" :vipType="typeInfo2"></selfTeacherVipCard>
        <selfTeacherVipCard :isRecommend="'selfVipCard recommend'" :vipType="typeInfo3"></selfTeacherVipCard>
        <selfTeacherVipCard :isRecommend="'selfVipCard'" :vipType="typeInfo5"></selfTeacherVipCard>
      </div>
      <div>
        <p class="title">一套属于我们自己的在线考试辅导平台</p>
        <div style="display: flex;flex-wrap: wrap;justify-content: space-between;margin-top: 20px;">
          <div class="use-easy-one">
            <p style="font-size:24px;font-weight:900;text-align:center">学生考试</p>
            <p style="margin-top:10px;font-size:18px;text-align:center">
              布置学生作业任务 | 在线题库刷题等 | 支持错题练习</p>
          </div>
          <div class="use-easy-one">
            <p style="font-size:24px;font-weight:900;text-align:center">无纸化考核</p>
            <p style="margin-top:10px;font-size:18px;text-align:center">
              在线考试,无纸化考核,系统安全稳定，页面流畅不卡顿。</p>
          </div>

          <div class="use-easy-one">
            <p style="font-size:24px;font-weight:900;text-align:center">历年真题</p>
            <p style="margin-top:10px;font-size:18px;text-align:center">
              NOIP、蓝桥杯、等级考试等历年试卷真题，打造VIP专属题库</p>
          </div>
          <div class="use-easy-one">
            <p style="font-size:24px;font-weight:900;text-align:center">模拟测试</p>
            <p style="margin-top:10px;font-size:18px;text-align:center">
              覆盖分类包含Scratch、C++、Python等多个领域，更贴近实际学习所需</p>
          </div>

          <div class="use-easy-one">
            <p style="font-size:24px;font-weight:900;text-align:center">不止提升</p>
            <p style="margin-top:10px;font-size:18px;text-align:center">
              累计合作百位专业教师，严控试题普及、考点、难易、严谨</p>
          </div>

          <div class="use-easy-one">
            <p style="font-size:24px;font-weight:900;text-align:center">引领行业</p>
            <p style="margin-top:10px;font-size:18px;text-align:center">
              甄选成套试题，历年真题、模拟试题、专属练习，知识绝不错过</p>
          </div>
        </div>
      </div>

      <div>
        <p class="title">教学中心 - 简单说明</p>
        <div style="display: flex;flex-wrap: wrap;justify-content: space-between;">
          <div class="use-easy-one">
            <p style="font-size:20px;font-weight:800;text-align:left;margin-left: 100px">
              1.添加学员账号(教学中心-学生管理)</p>
            <img src="@/assets/downloadImage/xinzeng.png" alt=""
                 style="width: 800px;height:400px;margin-left: 150px;margin-top: 20px">
            <p style="font-size:20px;font-weight:800;text-align:left;margin-left: 100px">
              2.布置作业，查询学员答题情况
            </p>
            <div style="margin-left: -30px">
              <img src="@/assets/downloadImage/xinzeng2.png" alt="" style="width: 850px;height:600px;margin-left: 160px;margin-top: 20px;">
            </div>
            <p style="font-size:20px;font-weight:800;text-align:left;margin-left: 100px">
              3.布置作业后，学生可在《我的作业》 选项中进行作业答题
            </p>
            <div style="margin-left: -30px">
              <img src="@/assets/downloadImage/1692090800.png" alt="" style="width: 850px;height:400px;margin-left: 180px;margin-top: 20px;">
            </div>
            <p style="margin-top:10px;font-size:20px;font-weight:800;text-align:left;margin-left: 100px">
              4.试卷专题分析</p>
            <div style="margin-left: -120px">
              <img src="@/assets/downloadImage/paper.png" alt=""
                   style="width: 1000px;margin-left: 180px;margin-top: 20px">
            </div>

            <p style="margin-top:10px;font-size:20px;font-weight:800;text-align:left;margin-left: 100px">5.记录课堂</p>
            <div>
              <img src="@/assets/downloadImage/class.png" alt=""
                   style="width: 900px;margin-left: 150px;margin-top: 20px">
              <img src="@/assets/downloadImage/class2.png" alt=""
                   style="width: 950px;margin-left: 130px;margin-top: 20px">
            </div>
            <p style="margin-top:10px;font-size:20px;font-weight:800;text-align:left;margin-left: 100px">
              6.提供专员录制自定义试题</p>
            <div style="text-align: left;margin-top: 20px;margin-left: 180px">
              可将需要录入的试卷/试题发送至邮箱wancode@163.com，会有专员录题，录制完成后将第一时间通知您
            </div>

          </div>
        </div>
      </div>
      <br>
      <br>
      <br>
      <div style="text-align: left;font-size: 16px;margin-top: 5px">
        <div style="text-align: left;font-size: 16px;margin-top: 20px;font-weight:900;" class="el-icon-document"> 会员开通说明</div>
        <div style="text-align: left;font-size: 16px;margin-top: 20px"> 一旦成为本站的会员，即可获得相应级别会员权限，全站全科目题库皆可查看答案！</div>

        <div style="text-align: left;font-size: 16px;margin-top: 20px"> 1、提供高品质会员题库资源，免费更新题库。</div>

        <div style="text-align: left;font-size: 16px;margin-top: 20px"> 2、会员属于赞助全凭自愿，无任何强逼行为，仅仅是您对于本站的支持（相当于赞助本站的服务器与维护费用），用于提高访问体验！</div>

        <div style="text-align: left;font-size: 16px;margin-top: 20px" > 3、本站作为考级竞赛题目的信息收集整理，可以联系客服获取1对1试卷讲解（另外收费）。</div>

        <div style="text-align: left;font-size: 16px;margin-top: 20px" > 4、本产品为虚拟产品，付款后不予退款；最终解释权归本站所有。</div>
      </div>
      <a class="join-vip">加入VIP，在线做练习</a>
      <!--
          <form name="punchout_form" method="post"
            action="https://openapi.alipay.com/gateway.do?charset=utf-8&method=alipay.trade.page.pay&sign=gI1bBGYzJKPT7UR7GdmGnXFVq8AwH3Ey80H7ldwfKJLya7cPXCjjESTX8hDnnkYAW2LgbYbZYChAb%2Fgmw1nKvhOJ5x6LHgK8pdSWPNuEjCMzzoRorn9Vw3LuXKSCVzUWea4OzLl6x1q8Cv4nG2wGS%2Fmp7ZJIZkrKzOrxqPGHkSO16GYbhGiPnMCTUPnRgQDgvHYDLcOPq6%2BegLAl55ezoIxJEmKbiLxMnIj5377v2BL4EcwOqlgKI%2BNtAV%2BAupfcVxMrJ3AWTM2XmmiER0CfFq1%2Fe4Eu%2BseyXTownmm2U9rfY5IGiJJdyA3b6wxScVMiWt3OgtV3FW%2FO94Yeotv0cQ%3D%3D&return_url=http%3A%2F%2F180.174.255.110%3A8585%2Falipay.trade.page.pay-JAVA-UTF-8%2Freturn_url.jsp&notify_url=http%3A%2F%2Fwww.wancode.net%3A8585%2Fwancode%2Fback%2Falipay%2Fcallback&version=1.0&app_id=2021001146647173&sign_type=RSA2&timestamp=2021-04-11+17%3A58%3A50&alipay_sdk=alipay-sdk-java-dynamicVersionNo&format=json">
            <input type="hidden" name="biz_content"
              value="{&quot;out_trade_no&quot;:&quot;WANCODE_20210411175850&quot;,&quot;total_amount&quot;:&quot;199.00&quot;,&quot;subject&quot;:&quot;开通会员&quot;,&quot;body&quot;:&quot;&quot;,&quot;timeout_express&quot;:&quot;360m&quot;,&quot;passback_params&quot;:&quot;&quot;,&quot;product_code&quot;:&quot;FAST_INSTANT_TRADE_PAY&quot;}">
            <input type="submit" value="立即支付" style="display:none">
          </form> -->
      <!-- selfVIP -->

    </div>
  </div>
</template>

<script>
import selfVipCard from "./components/selfVipCard.vue"
import selfTeacherVipCard from "./components/selfTeacherVipCard";

export default {
  components: {
    selfTeacherVipCard,
    selfVipCard
  },
  data() {
    return {
      activeName: 'first',
      studentPrise: true,
      teacherPrise: false,
      isShow: true,
      typeInfo1: {
        type: 4
      },
      typeInfo2: {
        type: 7
      },
      typeInfo3: {
        type: 5
      },
      typeInfo4: {
        type: 1
      },
      typeInfo5: {
        type: 6
      },
      // userVipType:null
    }
  },
  methods: {
    studentShow() {
      this.isShow = true
      this.studentPrise = true
      this.teacherPrise = false
    },
    teacherShow() {
      this.isShow = false
      this.teacherPrise = true
      this.studentPrise = false
    },

  },
  created() {

  },

  mounted() {
    // this.userVipType = localStorage.getItem("userVipType")
    // console.log(this.userVipType)
    const flag = this.$route.params.teacherPrise;
    // console.log(flag)
    if (flag === '1') {
      this.teacherShow()
    } else {
      this.studentShow()
    }

  }
}
</script>


<style lang="scss" scoped>

el-button--goon.is-active,
.el-button--goon:active {
  background: #48D1CC;
  border-color: #48D1CC;
  color: #fff;
}

.el-button--goon:focus,
.el-button--goon:hover {

  background: #20B2AA;
  border-color: #20B2AA;
  color: #fff;
}

.el-button--goon {
  background: #48D1CC;
  border-color: #48D1CC;
  color: #fff;
  //color: #FFF;
  //background-color: #20B2AA;
  //border-color: #20B2AA;
}

.a {
  background: #20B2AA;
  border-color: #20B2AA;
  color: #fff;
}

.b {
  background: #48D1CC;
  border-color: #48D1CC;
  color: #fff;
}

.index_section_title {
  margin-bottom: 38px;
  //color: #000;
  text-align: center;
  font-size: 38px;
  line-height: 32px;
  font-weight: 500;
  position: relative;
  //z-index: 2;
}

::v-deep .el-tabs__nav-scroll {
  width: 15% !important;
  margin: 0 auto !important;
}

.el-tabs__item {
  font-size: 1000px !important;
}

.big-title {
  font-size: 40px;
  font-weight: 500;
  color: rgba(50, 26, 0, 1);
  line-height: 56px;
  letter-spacing: 3px;
  margin-bottom: 15px;
  margin-top: 20px;
  text-align: center;
}

.title {
  font-size: 36px;
  font-weight: 600;
  color: rgba(50, 26, 0, 1);
  line-height: 50px;
  margin-top: 80px;
  text-align: center;
}

// .intro {
//   font-size: 30px;
//   font-weight: bold;
//   color: rgba(50, 26, 0, 1);
//   padding-left: 43px;
//   display: block;
//   margin-top: 64px;
// }
.use-easy-one {
  width: 50%;
  text-align: center;
  margin-top: 80px;
}

// .use-easy .use-easy-one .sub-intro {
//   padding-left: 43px;
//   display: block;
//   font-size: 18px;
//   font-weight: 400;
//   color: rgba(50, 26, 0, 1);
//   margin-top: 24px;
// }


a.join-vip {
  width: 363px;
  height: 62px;
  background-color: #FF970F;
  border-radius: 30px;
  font-size: 26px;
  font-weight: 600;
  color: white;
  line-height: 62px;
  margin-top: 20px;
  margin-bottom: 20px;
}

a.join-vip {
  width: 360px;
  height: 60px;
  line-height: 60px;
  background: linear-gradient(20deg, rgba(255, 150, 48, 1), rgba(255, 151, 3, 1));
  border-radius: 30px;
  text-align: center;
  display: block;
  margin: 60px auto 0 auto;
  font-size: 24px;
  font-weight: bold;
  color: rgba(255, 255, 255, 1);
}

a {
  color: #666666;
  text-decoration: none;
}
</style>
