<template>
  <div>
    <div :class="isRecommend" v-if="vipType.type ===4">
      <div style="height:380px;border-bottom: #B3C0D1 1px solid;">
        <h3>月费 SVIP</h3>
        <p style="text-decoration:line-through;text-align:center">599元</p>
        <p class="price orange" style="margin-top: 10px;">199
          <span>元/月</span>
        </p>
        <div style="width: 100%;text-align: center;margin-top: 40px;">
          <el-button @click="getTeacherVip" type="primary">
            购买教师版会员
          </el-button>
          <p class="sears-desc" style="margin-top: 30px;">包含 <span
              style="color: red; height: 20px; font-size: 20px;">5</span> 个学生账号</p>
          <p class="sears-desc" style="margin-top: 10px;">全站试卷 <span
              style="color: red; height: 20px; font-size: 20px;">15次/日</span> 作答</p>

        </div>
      </div>
      <div style="text-align: center;height: 200px;">
        <p style="line-height:35px;margin:0 auto;margin-top: 10px">有效期 30 天 </p>
        <p style="line-height:35px;margin:0 auto">老师和学生账号全站试卷<span style="color: red;height: 20px;font-size: 20 px; ">均可作答</span></p>
        <p style="line-height:35px;margin:0 auto">学生账号到期时间同老师账号</p>
        <p style="line-height:35px;margin:0 auto">试卷：15次/日</p>
        <p style="line-height:35px;margin:0 auto">试卷下载：不支持</p>
      </div>
    </div>


    <div :class="isRecommend" v-if="vipType.type ===7">
      <div style="height:380px;border-bottom: #B3C0D1 1px solid;">
        <h3>三月 SVIP</h3>
        <p style="text-decoration:line-through;text-align:center">1599元</p>
        <p class="price orange" style="margin-top: 10px;">399
          <span>元/3月</span>
        </p>
        <div style="width: 100%;text-align: center;margin-top: 40px;">
          <el-button @click="getTeacherVip" type="primary">
            购买教师版会员
          </el-button>
          <p class="sears-desc" style="margin-top: 30px;">包含 <span
              style="color: red; height: 20px; font-size: 20px;">10</span> 个学生账号</p>
          <p class="sears-desc" style="margin-top: 10px;">全站试卷 <span
              style="color: red; height: 20px; font-size: 20px;">25次/日</span> 作答</p>
        </div>
      </div>
      <div style="text-align: center;height: 380px;">
        <p style="line-height:35px;margin:0 auto;margin-top: 10px">有效期 90 天 </p>
        <p style="line-height:35px;margin:0 auto">老师和学生账号全站试卷<span style="color: red;height: 20px;font-size: 20 px; ">均可作答</span></p>
        <p style="line-height:35px;margin:0 auto">学生账号到期时间同老师账号</p>
        <p style="line-height:35px;margin:0 auto">试卷：25次/日</p>
        <p style="line-height:35px;margin:0 auto">试卷下载：<span
            style="color: red; height: 20px; font-size: 20px;">支持</span></p>
      </div>
    </div>


    <div :class="isRecommend" v-if="vipType.type ===5">
      <div style="height:380px;border-bottom: #B3C0D1 1px solid;">
        <h3>年费 SVIP</h3>
        <p style="text-decoration:line-through;text-align:center">3000元</p>
        <!--				<p style="color: #FF0000;font-size: 18px;text-align:center">2021.05.20 恢复标准价</p>-->
        <p class="price orange" style="margin-top: 10px;">999
          <span>元/年</span>
        </p>
        <div style="width: 100%;text-align: center;margin-top: 40px;">
          <el-button @click="getTeacherVip" type="primary">
            购买教师版会员
          </el-button>
          <p class="sears-desc" style="margin-top: 30px;">包含 <span
              style="color: red; height: 20px; font-size: 20px;">50</span> 个学生账号</p>
          <p class="sears-desc" style="margin-top: 10px;">全站试卷 <span
              style="color: red; height: 20px; font-size: 20px;">30次/日</span> 作答</p>
        </div>
      </div>
      <div style="text-align: center;height: 300px;margin-top: 10px">
        <p style="line-height:35px;margin:0 auto">有效期 1 年 </p>
        <p style="line-height:35px;margin:0 auto">老师和学生账号全站试卷<span style="color: red;height: 20px;font-size: 20 px; ">均可作答</span></p>
        <p style="line-height:35px;margin:0 auto">学生账号到期时间同老师账号</p>
        <p style="line-height:35px;margin:0 auto">试卷：30次/日</p>
        <p style="line-height:35px;margin:0 auto">试卷下载：<span
            style="color: red; height: 20px; font-size: 20px;">支持</span></p>

      </div>

    </div>


    <div :class="isRecommend" v-if="vipType.type ===6">

      <div style="height:380px;border-bottom: #B3C0D1 1px solid;">
        <h3>三年 SVIP</h3>
        <p style="text-decoration:line-through;text-align:center">5000</p>
        <p class="price orange" style="margin-top: 10px;">1999
          <span>元/3年</span>
        </p>
        <div style="width: 100%;text-align: center;margin-top: 40px;">
          <el-button @click="getTeacherVip" type="primary">
            购买教师版会员
          </el-button>
          <p class="sears-desc" style="margin-top: 30px;">包含 <span
              style="color: red; height: 20px; font-size: 20px;">200</span> 个学生账号</p>
          <p class="sears-desc" style="margin-top: 10px;">全站试卷 <span
              style="color: red; height: 20px; font-size: 20px;">30次/日</span> 作答</p>
          <!--					<p class="sears-desc" style="margin-top: 10px;">全站试卷 <span style="color: red; height: 20px; font-size: 20px;">无限制</span> 作答</p>-->
        </div>
      </div>
      <div style="text-align: center;height: 200px;">
        <p style="line-height:35px;margin:0 auto;margin-top: 10px"></p>
        <p style="line-height:35px;margin:0 auto">有效期 3 年 </p>
        <p style="line-height:35px;margin:0 auto">老师和学生账号全站试卷<span style="color: red;height: 20px;font-size: 20 px; ">均可作答</span></p>
        <p style="line-height:35px;margin:0 auto">学生账号到期时间同老师账号</p>
        <p style="line-height:35px;margin:0 auto">试卷：30次/日</p>
        <p style="line-height:35px;margin:0 auto">试卷下载：<span
            style="color: red; height: 20px; font-size: 20px;">支持</span></p>

      </div>

    </div>
    <div id="submitFormDiv">

    </div>
  </div>
</template>

<script>
import {
  openTeacherVip
} from "@/api/index.js"

export default {
  filters: {
    formatType(type) {
      switch (type) {
        case 1:
          return "会员"
          break;
        case 2:
          return "会员"
          break;
        case 3:
          return "会员-周"
          break;
        case 4:
          return "会员-月"
          break;
        case 5:
          return "会员-年"
          break;
        case 6:
          return "会员-3年"
          break;
        case 7:
          return "会员-3月"
          break;
        default:
          break;
      }
    }
  },
  props: {
    isRecommend: {
      type: String,
      default: 'selfVipCard'
    },
    vipType: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      userVipType: null,
      userId: 1,
      radio1: 'Scratch',
      value: '1',
      options: [{
        value: '1',
        label: '一级'
      }, {
        value: '2',
        label: '二级'
      }, {
        value: '3',
        label: '三级'
      }, {
        value: '4',
        label: '四级'
      }]
    }
  },
  mounted() {
    this.userVipType = this.vipType.type
    this.userId = localStorage.getItem("userId")
  },
  methods: {
    getTeacherVip() {

      let param = {
        userId: Number(this.userId),
        userVipType: this.userVipType.toString(),
        name: this.radio1,
        level: this.value
      }
      openTeacherVip(param).then(res => {
        if (res.code === "0000") {
          const div = document.createElement('div')
          /* 此处form就是后台返回接收到的数据 */
          div.innerHTML = res.data
          document.body.appendChild(div)
          document.forms[0].setAttribute('target', '_blank') // 新开窗口跳转
          document.forms[0].acceptCharset = 'utf-8';
          document.forms[0].submit()
        }
        location.reload()
      })
    },
  }
}
</script>

<style lang="scss" scoped>
h3 {
  font-size: 26px;
  text-align: center;
  margin: 25px 0 13px;
  font-weight: bold;
  color: rgba(50, 26, 0, 1);
  line-height: 35px;
  letter-spacing: 2px;
  padding-top: 20px;
}

.orange {
  color: #CC7400;
}

.price {
  height: 70px;
  font-size: 50px;
  text-align: center;
  margin-bottom: 20px;
}

.sears .price span {
  font-size: 16px;
  color: #321A00;
  font-weight: 600;
  position: relative;
}

.price .year {
  vertical-align: 1px;
  margin-left: 3px;
}

.selfVipCard {
  width: 300px;
  height: 600px;
  background-color: #FFFFFF;
  overflow: hidden;
  position: relative;
  border-radius: 5px;
  box-shadow: 2px 2px 2px #64390F;
  border: 1px solid #FFFFFF;
  transition: all .1s ease-in;
}

.selfVipCard:hover {
  border: 3px solid #64390F;
  // border-top: 2px solid #FF69B4;
  transform: translateY(-10px);
  box-sizing: border-box;
  box-shadow: 5px 5px 2px #64390F;
}

.recommend::before {
  content: "";
  position: absolute;
  width: 30px;
  height: 180px;
  background-color: #64390F;
  transform: rotate(45deg);
  top: -50px;
}

.recommend::after {
  content: "推 荐";
  color: #FFFFFF;
  transform: rotate(-45deg);
  width: 50px;
  height: 30px;
  left: 8px;
  top: 12px;
  position: absolute;
  font-weight: 900;
  font-size: 18px;
  // margin-top: -20px;
}

.origin-price-show {
  opacity: 1;
  font-size: 16px;
  color: #A5A5A5;
  width: 80%;
}

.iconfont {
  color: #FF0000;
  font-size: 18px;
  position: relative;
}

.limit-c {
  // float: right;
  color: #FF0000;
  font-size: 12px;
  line-height: 22px;
}
</style>
